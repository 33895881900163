import React from "react"
import "./layout.css"

export default function Layout({ children }) {
    return (
        <div lang="zh-Hant-TW" style={{position: "relative"}}>
            <div style={{position: "relative", zIndex: "100"}}>
                {children}
            </div>
        </div>
    )
}
